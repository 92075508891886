main .card {
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

main .login_container {
  height: -webkit-fill-available;
}

main .card .card-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
a{
  text-decoration: "none"!important;
}
main .card logo {
  width: 14.5rem;
}

main .card .card-body .row {
    display: flex;
    justify-content: center;
    align-items: center;
}

main .card .card-body .row label {
  font-size: 16px;
  font-weight: 700;
}

main .card .card-body .row input,
main .card .card-body .row select {
  height: 40px;
  border-radius: 3px;
  border: 0.5px solid #b5bbc0 !important;
  border-radius: 4px;
  padding-left: 15px;
  outline: none;
  background-color: #fff !important;
  font-family: "Calibri";
  font-size: 18px;
  box-sizing: border-box;
}
